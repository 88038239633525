import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import GateThreeSessionHandler from '../gateThree/GateThreeSessionHandler';
import jwtDecode from 'jwt-decode';
import DatadogController from './DatadogController';
import { siteLogout } from '../../../util/logout';

const TokenManager = () => {
    const { instance } = useMsal();
    const [token, setToken] = useState(null);

    useEffect(() => {
        fetchToken();
    }, []);

    const fetchToken = async () => {
        try {
            const activeAccount = instance.getActiveAccount();
            if (activeAccount) {
                const accessTokenRequest = {
                    account: activeAccount,
                };
                const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
                console.log("Id token successfully acquired");
                setToken(accessTokenResponse.idToken);
            } else {
                throw Error("Error retrieving active account information");
            }
        } catch (error) {
            console.error("Error retrieving token silently: ", error);
            siteLogout();
        }
    };

    if (token) {
        const userEmail = jwtDecode(token).preferred_username;
        DatadogController.recordUserInfo(userEmail);
    }

    // Renders GateThreeSessionHandler when token exists otherwise renders nothing
    return token && <GateThreeSessionHandler token={token}/>;
};

export default TokenManager;